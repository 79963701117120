<template>
    <div>
        <div class="table_content">

            <!-- 表格内容 -->
            <commonTitle></commonTitle>

            <div class="search">
                <el-form ref="form" :model="form" label-width="70px" :inline="true">
                    <el-form-item label="楼幢号:">
                        <el-input v-model="form.name" placeholder="请输入楼幢号" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
                    <button @click.prevent="add" style="cursor: pointer;" v-show="isShow">新建</button>
                </el-form>
            </div>

            <el-card>

                <el-table :data="tableData"
                          v-loading="loading"
                          style="width:100%"
                          element-loading-text="拼命加载中"
                          
                          :default-sort="{prop: 'settingTime', order: 'descending'}"
                          :stripe="true">
                    <el-table-column fixed prop="towerId" label="楼幢id" width="150" v-if="false">
                    </el-table-column>
                    <el-table-column fixed label="楼幢号" width="150">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="lookUnit( scope.row)">
                                {{scope.row.towerNum}}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="villageName" label="所属小区" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="towerType" label="楼幢类型" width="120">
                    </el-table-column>
                    <el-table-column prop="elevatorType" label="是否有电梯" width="120">
                    </el-table-column>
                    <el-table-column prop="floorNum" label="楼层数" width="120">
                    </el-table-column>
                    <el-table-column prop="unitNum" label="单元数" width="120">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="220">
                        <template slot-scope="scope">
                            <el-button @click="toHuShi(scope.row)" type="text" size="mini"
                                       :disabled="scope.row.actResult!=4">生成户室
                            </el-button>
                            <el-button @click="update(scope.row)" type="text" size="mini"
                                       :disabled="scope.row.actResult!=4">修改
                            </el-button>
                            <el-button @click.native.prevent="deleteRow(scope.$index, scope.row,tableData)" type="text"
                                       size="mini" :disabled="scope.row.actResult!=4">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-row>

                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="15"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>

                    <el-button @click="back" class="btn" size="small">上一步</el-button>

                </el-row>
            </el-card>
        </div>
    </div>
</template>

<script>
    import commonTitle from "./commonTitle";

    export default {
        name: "deveTower",
        components: {
            commonTitle
        },
        data() {
            return {
                form: {
                    name: "",
                    region: '',
                },
                tableData: [],
                multipleSelection: [],
                totalNum: 15,
                url: this.$Config.base_server,
                loading: false,
                isShow: '',
                data: {
                    access_token: localStorage.getItem("token"),
                    limit: 15,
                    villageId: JSON.parse(localStorage.getItem("villageData")).villageId
                }
            };
        }, mounted() {
            this.common();
            let actResult = JSON.parse(localStorage.getItem("villageData")).actResult;
            if (actResult == 4) {
                //小区未提交状态
                this.isShow = true;
            } else {
                //小区其他状态
                this.isShow = false;
            }
        }, methods: {
            common(currentPage = 0) {

                const that = this;
                this.loading = true;

                that.data.page = currentPage;
                that.data.towerNum = that.form.name;
                $.ajax({
                    url: this.url + "/api-public/opetower/list",
                    data: that.data,
                    contentType: 'application/json',
                    type: 'get',
                    success: function (result) {
                        if (result.code == 0) {
                            that.loading = false;
                            that.tableData = result.data;
                            that.totalNum = result.count;
                        }
                    }
                });
            },
            toHuShi(data) {
                localStorage.setItem("batchProduct", JSON.stringify(data));
                this.$router.push({name: "batchProduct", params: data})
            },
            back() {
                this.$router.push('/table');
            },
            update(row) {
                //修改楼幢的页面
                this.$router.push({name: 'upTower', params: {towerData: row}});  //传过去选中的这一行值
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            },
            lookUnit(row) {
                // localStorage.setItem("tower", JSON.stringify(row));
                // window.location.href = "deveUnit.html";
                this.$router.push({name: 'deveUnit'});//跳转到单元页面 并带过去选中楼幢的值
                //存储楼幢信息
                localStorage.setItem("towerData", JSON.stringify(row));
            },
            deleteRow(index, rows, tableData) {
                //删除按钮
                let _this = this;
                let _rows = rows;
                //删除相应楼幢
                debugger;
                this.$confirm('此操作将永久删除该楼幢信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    $.ajax({
                        url: _this.url + "/api-public/opetower/delete/" + _rows.towerId + "?access_token=" + localStorage.getItem("token"),
                        type: 'delete',
                        success: function (data) {
                            if (data.resp_code == 0) {
                                tableData.splice(index, 1);
                                //成功
                                _this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                            }
                        }
                    });
                }).catch(function () {

                });
            }, add() {
                //新增按钮
                this.$router.push({name: 'addTower'});
            }
        }
    }
</script>

<style scoped>

    .btn {
        float: right;
        transform: translateY(-38px);
    }
</style>